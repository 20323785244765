import React, { ReactNode } from "react";
import style from "./style.module.scss";
import classnames from "classnames";
import {
    Typography,
    Colors as TypographyColors,
} from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";

export type Colors =
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "error"
    | "grey"
    | "transparent"
    | "backgroundPage";

interface PropTypes {
    children: string | ReactNode;
    color?: Colors;
    textColor?: TypographyColors;
    padding?: "default" | "xs";
    rounded?: boolean;
    id?: string;
}

export const Badge = ({
    children,
    color = "primary",
    textColor = "white",
    padding = "default",
    rounded = false,
    id = "default",
}: PropTypes): JSX.Element => {
    return (
        <div
            className={classnames(
                style.badge,
                style[color],
                style[`padding-${padding}`],
                rounded ? style.rounded : style.defaultRadius,
            )}
            data-test-id={`Badge.${id}`}
        >
            <Typography color={textColor} variant="micro" data-test-id={`Badge.Label.${id}`}>
                {children}
            </Typography>
        </div>
    );
};

Badge.displayName = "Badge";
